/**
 * helper function
 */
function pre(obj) {
  console.log(obj);
}

/**
 * Class controlling the UI.
 */
window.old_ui = {
  initDataTables: function () {
    /**
     * Client side data tables
     *
     * table.data_table
     * - can load data from the server if: data-source is set.
     * - can have a filter if: data-filter-fields is set.
     * - can set the aoColumns based on the thead th aoColumn attribute
     *
     */
    $('table.data_table').not('.ok-data_table').addClass('ok-data_table').each(function () {
      // Default layout, it changes for excel export
      var sDom = '<"H"lfr>t<"F"ip>';
      // Get the coulmn types
      var aoColumns = [];

      var aoColumnOptions = $(this).attr('data-column-fields');
      if (aoColumnOptions && aoColumnOptions != '') {
        aoColumns = jQuery.parseJSON(aoColumnOptions);
      } else {
        $(this).find('thead th').each(function () {
          var aoColumn = null;
          if ($(this).attr('aocolumn')) {
            aoColumn = jQuery.parseJSON($(this).attr('aocolumn'));
          }
          aoColumns.push(aoColumn);
        });
      }

      // If we have a data-source, we make server processing
      var dataSource = $(this).attr('data-source');
      // If we have data-filter-fields, we have export to excel also.
      var columnFilterOptions = $(this).attr('data-filter-fields');

      var oTableTools = undefined;
      if (columnFilterOptions != undefined && dataSource != undefined) {
        sDom = '<"H"lfr>t<"F"ipT>';
        oTableTools = {
          "sSwfPath": "/scripts/jquery.tabletools-2.1.3/swf/copy_csv_xls_pdf.swf",
          "aButtons": [
            {
              "sExtends": "download",
              "sButtonText": jsLang.hotel.book.export_to_excel,
              "sUrl": dataSource
            }
          ]
        };
        columnFilterOptions = jQuery.parseJSON(columnFilterOptions);
      }

      var obj = $(this).dataTable({
        responsive: true,
        "oLanguage": jsLang.dataTable,
        "bJQueryUI": false,
        "iDisplayLength": 25,
        "aaSorting": [],
        "aoColumns": aoColumns,
        "bProcessing": (dataSource == undefined ? false : true),
        "bServerSide": (dataSource == undefined ? false : true),
        "sAjaxSource": dataSource,
        "oSearch": {"sSearch": $.urlParam("sSearch")},
        "fnDrawCallback": function () {
          // ui.updateDynamicElements();
          // Highlight whatever we searched for
          $('.dataTable > tbody > tr > td, .dataTable > tr > td').removeHighlight();
          if ($('.dataTables_filter input').val() != "") {
            $('.dataTable > tbody > tr > td, .dataTable > tr > td').highlight($('.dataTables_filter input').val());
          }
          // Scroll to top
          $('body').animate({
            scrollTop: parseInt($(".dataTable").offset().top)
          }, 0);
        },
        "sDom": sDom,
        "oTableTools": oTableTools
      });

      return 1;
    });
  },
  initConfirmDelete: function () {
    // Nice delete
    $('body').on("click", ".delete, .confirm", function (e) {

      var targetUrl = $(this).attr("href");
      // var confirm = jsLang.hotel.book.confirm;
      // var cancel = jsLang.hotel.book.cancel;
      // var buttons = {};
      // buttons[confirm] = function () {
      //   window.location.href = targetUrl;
      // };
      // buttons[cancel] = function () {
      //   $(this).dialog("close");
      // };

      let message = $(this).data("message") || "Please confirm";
      confirmDialog(targetUrl, message);
      return false;
    });

    // Close buttons
    $('body').on('click', '.close-button', function (event) {
      $('#dialog-confirm').hide();
      return false;
    });

  },
  initAjaxDialogs: function () {
    $('body').on('click', 'a.ajax-dialog', function (event) {
      event.preventDefault();
      event.stopPropagation();
      var targetUrl = $(this).attr("href");
      var title = $(this).attr("title");
      if (!title) {
        title = '';
      }
      ajaxDialog(targetUrl, title);

      return false;
    });

    // Close buttons
    $('body').on('click', '.close-button', function (event) {
      $('#dialog-message').hide();
      return false;
    });

    // Close on window click
    window.onclick = function (event) {
      console.log('window.onclick');
      if (event.target == document.getElementById('dialog-message')) {
        $('#dialog-message').hide();
      }
    };

    $(document).keyup(function (e) {
      console.log('$(document).keyup');
      if (e.keyCode == 27) { // escape key maps to keycode `27`
        $('#dialog-message').hide();
      }
    });
  },


  /**
   * We might need from to sliders.
   */
  initSliders: function () {
    // Minute sliders show a range from 00:15 to 24:00, but post the unix time stamp slit by :
    $("input.slider-minutes").not('.ok-slider-minutes').addClass('ok-slider-minutes').each(function () {
      console.log('sliders');
      var id = $(this).attr('id');
      console.log(id);
      var defaults = $(this).val().split(':');
      console.log(defaults);
      if (defaults.length != 2) {
        console.log('Invalid slider minutes defaults.');
      }
      var label = 'From ' + gmdate("g:ia", defaults[0]) + " to " + gmdate("g:ia", defaults[1]);
      if (label == 'From 0:00 to 0:00') {
        label = 'Any time';
      }

      // We need a DIV for the slider, one for the label, and another for the hidden field to post.
      $(this).after('<div id="slider_' + id + '"></div>');
      $(this).after('<div id="' + id + '" class="slider-minutes-label">' + label + '</div>');
      $(this).after('<input type="hidden" id="hidden_' + id + '" name="' + $(this).attr('name') + '" value="' + $(this).val() + '">');
      $(this).remove();

      //var display_value = $(this).data('autocomplete-value');
      $('#slider_' + id).slider({
        range: true,
        value: 100,
        min: 0,
        max: 86399, // 24h
        step: 900,	// 15 min
        values: [defaults[0], defaults[1]],
        slide: function (event, ui) {
          // minimum 15 min between the two
          if (ui.values[1] <= (86400 - 900) && ui.values[0] == ui.values[1]) {
            ui.values[1] += 900;
            $('#slider_' + id).slider("values", 1, ui.values[1]);
          } else if (ui.values[0] >= 900 && ui.values[0] == ui.values[1]) {
            ui.values[0] -= 900;
            $('#slider_' + id).slider("values", 0, ui.values[0]);
          }
          // Set the labels
          var label = 'From ' + gmdate("G:i", ui.values[0]) + " to " + gmdate("G:i", ui.values[1]);
          if (label == 'From 0:00 to 0:00') {
            label = 'Any time';
          }
          $('#' + id).html(label);
          // And hidden field
          $('#hidden_' + id).val(ui.values[0] + ":" + ui.values[1]);
        },
        stop: function (event, ui) {
          $('#hidden_' + id).change();
        }
      }).css({'margin-left': '5px', 'margin-right': '5px'})

    });

  },

  /**
   * Some inputs must be upper case
   * Must be called before the validation
   */
  initToUpperCase: function () {

    $('body').on('input change propertychange', 'textarea.mask-uppercase, input.mask-uppercase', function (e) {
      if (!$(this).is(':placeholder-shown')) {
        e.target.value = e.target.value.toUpperCase();
      }
    });
    $("textarea.mask-uppercase, input.mask-uppercase").trigger("change");
  },

  initMultiSelects: function () {
    $("select[multiple]").select2({
      theme: "classic"
    });
  },


  /**
   * Textareas don't have pattern, so we use JS for it
   * Use the JS for all of them
   */
  initInputPattern: function () {
    var errorMessage = "Please match the requested format.";

    // Add the placeholder and validate the patterns
    $('body').on('input change propertychange', 'input, textarea, select', function () {
      let parent = $(this).closest('.input-wrapper');
      if (typeof parent === typeof undefined || parent === false) {
        return;
      }

      // Placeholder
      if ($(this).is(':placeholder-shown')) {
        parent.addClass('placeholder-shown');
      } else {
        parent.removeClass('placeholder-shown');
      }
      // Pattern
      var pattern = $(this).attr("pattern");
      if (typeof pattern !== typeof undefined && pattern !== false) {
        let pattern2 = pattern.replace(/^\^|\$$/g, '');
        let pattern1 = pattern2.replace(/^\(\?i\)/g, '');
        let modifier = 'g';
        if (pattern1 != pattern2) {
          modifier = modifier + 'i';
        }
        var patternRegex = new RegExp("^" + pattern1 + "$", modifier);

        let isValid = $(this).val().match(patternRegex);

        if (isValid) {
          parent
            .removeClass("invalid")
            .addClass("valid")
            .attr("title", '');
        } else {
          parent
            .removeClass("valid")
            .addClass("invalid")
            .attr("title", errorMessage);

        }
      }

      // keep the textarea height the same as the input
      if ($(this).is('textarea')) {
        let rows = $(this).val().split(/\n/).length;
        if (rows < 2) {
          rows = 2;
        }
        if ($(this).attr('rows') != rows) {
          $(this).attr('rows', rows);
        }
      }

    });

    // Change the inputs
    $('input, textarea, select').trigger("change");
  },

  initInputWrappers: function () {
    // Toggle parent focus field
    $("input, select, textarea").on("focus blur", function () {
      if ($(this).is(':focus')) {
        $(this).parent('.input-wrapper').addClass('focus');
      } else {
        $(this).parent('.input-wrapper').removeClass('focus');
      }
    });

    // Focus what we clicked
    $(".input-wrapper").click(function (e) {
      if (e.target != this) return;
      $(this).find("input, select, textarea").first().focus();
    });

    // Default focus
    $(':focus').parent('.input-wrapper').addClass('focus');
    console.log('initInputWrappers');
  },

  initAll() {

    this.initDataTables();
    this.initConfirmDelete();
    this.initAjaxDialogs();
    this.initToUpperCase();
    this.initSliders();

    this.initMultiSelects();


    this.initInputPattern();
    this.initInputWrappers();
  }
};

window.$ = $;
$(function () {
  /**
   * Gets an argument off the url
   */
  $.urlParam = function (name) {
    var results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(top.window.location.href);
    return (results !== null) ? unescape(results[1]) : '';
  };

  /**
   * Inputs/special tags
   */
  window.old_ui.initAll();
});

/**
 * Add a wrapper to all inputs
 */
// $('input[data-label]').each(function (e) {
// let _class = $(this).attr('class');
// let _styles = $(this).attr('style');
// if(_styles) {
//   _styles = ' style="' + _styles + '"';
// }else{
//   _styles = '';
// }
// if(_class) {
//   _class = ' ' + _class;
// }else{
//   _class = '';
// }

// $(this).wrap('<div class="input-wrapper'+_class+'"'+_styles+'></div>').after('<label class="label-placeholder></label>');
// $(this).wrap('<div class="input-wrapper"></div>').append('<label class="label-placeholder></label>');
//
// console.log($(this));
// });

// $(function() {

//
//   var $inputItem = $("input[data-label]");
//   $inputItem.length && $inputItem.each(function() {
//     var $this = $(this),
//       $input = $this.find(".formRow--input"),
//       placeholderTxt = $input.attr("placeholder"),
//       $placeholder;
//
//     $input.after('<span class="placeholder">' + placeholderTxt + "</span>"),
//       $input.attr("placeholder", ""),
//       $placeholder = $this.find(".placeholder"),
//
//       $input.val().length ? $this.addClass("active") : $this.removeClass("active"),
//
//       $input.on("focusout", function() {
//         $input.val().length ? $this.addClass("active") : $this.removeClass("active");
//       }).on("focus", function() {
//         $this.addClass("active");
//       });
//   });
//
//   console.log( "ready!" );
// });


/**
 * Ajax popup
 *
 * @param url
 * @param title
 * @param loadingMessage
 */
window.ajaxDialog = function (url, title, loadingMessage) {
  if (!loadingMessage) {
    loadingMessage = 'Loading ...';
  }
  $('#dialog-message .modal-title').html(title + "<b>" + loadingMessage + "</b>");
  $('#dialog-message .dialog-content').html('<img src="/images/loading.gif" width="100" height="100" alt="Loading ..." title="Loading ..." />');
  $('#dialog-message .dialog-content').load(url, function (response, status, xhr) {
    if (status == "error") {
      var msg = "Sorry but there was an error: ";
      $('#dialog-message .modal-title').html(msg + xhr.status + " " + xhr.statusText);
    } else {
      $('#dialog-message .modal-title').html(title);
    }
  });


  $('#dialog-message .close-button').click(function (event) {
    event.preventDefault();
    event.stopPropagation();
    $('#dialog-message').hide();
  });

  $('#dialog-message').show();
}

/**
 * Open the confirm dialog
 *
 * @param url
 * @param message
 */
function confirmDialog(url, message) {
  $('#dialog-confirm .modal-message').html(message);
  $('#dialog-confirm .confirm-button').attr('href', url);

  $('#dialog-confirm .close-button').click(function (event) {
    event.preventDefault();
    event.stopPropagation();
    $('#dialog-confirm').hide();
  });


  $('#dialog-confirm').show();
}
